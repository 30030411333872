import React from 'react'

function MainEducation() {
    return (
        <>
            {/* Education */}
            <section className="box-container">
                <div className="box">
                    <article className="card">
                        <h2>College</h2>
                        <h3>2021 - 2024</h3>
                        <p>IT-Högskolan, Frontend-utvecklare</p>
                        <p>
                            Högskolan i Borås: Cybersäkerhet för uppkopplade
                            enheter (distans)
                        </p>
                        <p>
                            Kristianstad högskola: Apputveckling för Android
                            (distans)
                        </p>
                    </article>
                </div>

                <div className="box">
                    <article className="card">
                        <h2>High School</h2>
                        <h3>2018 - 2021</h3>
                        <p>Åva Gymnasium, Informations- och medieteknik</p>
                    </article>
                </div>
            </section>
        </>
    )
}

export default MainEducation
