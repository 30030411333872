import React from 'react'

function MainHeader() {
    return (
        <>
            <header className="main-content">
                <p>
                    Hello, my name is Joacim Strandvide and I am a front-end
                    developer with a strong interest in programming,
                    cybersecurity, and technology in general.
                </p>
            </header>
        </>
    )
}

export default MainHeader
