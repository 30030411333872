import React from 'react'
import HeaderComp from './components/HeaderComp'
import FooterComp from './components/FooterComp'
import MainProjects from './components/MainProjects'
import MainHeader from './components/MainHeader'
import MainSkills from './components/MainSkills'
import MainEducation from './components/MainEducation'

function App() {
    return (
        <>
            <HeaderComp />
            <MainHeader />
            <MainSkills />
            <MainProjects />
            <MainEducation />
            <FooterComp />
        </>
    )
}

export default App
