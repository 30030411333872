import React from 'react'

function MainSkills() {
    return (
        <>
            {/* Skills */}
            <section className="skill-container">
                <article className="skill-card">
                    <h2>Frontend Skills</h2>
                    <p>HTML5 & CSS3</p>
                    <p>Javascript</p>
                    <p>VueJS</p>
                    <p>React</p>
                    <p>Typescript</p>
                </article>

                <article className="skill-card">
                    <h2>Backend Skills</h2>
                    <p>Node.js</p>
                    <p>Next.js</p>
                    <p>SQL</p>
                    <p>PHP</p>
                    <p>GraphQL</p>
                </article>

                <article className="skill-card">
                    <h2>Tools</h2>
                    <p>Figma</p>
                    <p>Linux</p>
                    <p>Git & Github</p>
                    <p>Docker</p>
                    <p>MongoDB</p>
                    <p>PostgreSQL</p>
                </article>
            </section>
        </>
    )
}

export default MainSkills
